import {api} from './api';

export const environment = {
  production: true,
  googleMapsApiKey: '',
  appName: '',
  env: 'PROD',
  api: api,
  apiUrl: api.base,
  storageBaseUrl: api.storageBase,
  isUsaVersion: api.isUsaVersion,
  recaptchaSiteKeyClient: '6LeuuacUAAAAABwfssSKlQub6aZQ2tw1jCc9iUjQ',
  dateFormat: 'MM/DD/YYYY',
};
