import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { sortBy } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Scrollbar } from 'smooth-scrollbar/scrollbar';
import { User } from '../../../../modules/security/models/user.model';
import { UserService } from '../../../../modules/security/services/user.service';
import { ScrollbarComponent } from '../../../../core/scrollbar/scrollbar.component';
import { componentDestroyed } from '../../../../core/utils/component-destroyed';
import { ChatService } from './chat.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig, MatTableDataSource } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import { ContentsService } from 'src/app/pages/contents/services/contents.service';
import { Subject, Subscription } from 'rxjs';
import { MessagingServiceService } from 'src/app/pages/shared/services/messagingServiceService';
import { TreatmentsService } from 'src/app/pages/treatments/treatments.service';

@Component({
  selector: 'nuvolaweb-treatment-chat',
  templateUrl: './treatment-chat.component.html',
  styleUrls: ['./treatment-chat.component.scss']
})
export class TreatmentChatComponent implements OnInit {
  @ViewChild('chatSentencesModal', { static: true }) chatSentencesModal: MatDialogRef<any>;
  @ViewChild('sendNotificationModal', { static: true }) sendNotificationModal: MatDialogRef<any>;

  @Input() treatment: any;
  @Input() quote: any;
  @Input() chatType: string = "T";    //T=treatment, Q=Quote; I=Incomplete Treatments
  @Input() forceDisabeNewChatMessages: boolean = false;
  @Input() allowUploadImages: boolean = false;

  @Output() isUserChatOwner = new EventEmitter<boolean>();

  //@Input() refreshIncompleteChat: Subject<boolean>;

  _subscription: Subscription;

  scrollbar: Scrollbar;

  drawerOpen = true;
  drawerMode = 'side';
  drawerDisableClose = true;

  messages: any[] = [];
  newMessage: string;

  currentLanguage: string ='';
  chatSentenceCategorySelected:number = 0;
  chatSentences: any[] = [
    {
      'id': 1,
      'sentence_it': 'Buongiorno Dottore visionando le fotografie il caso dovrebbe rientrare in un livello A. Cordiali Saluti.',
      'sentence_en': 'Dear Doctor, By viewing the photographs the case should fall in a level A.'
    },
    {
      'id': 1,
      'sentence_it': 'Buongiorno Dottore visionando le fotografie il caso dovrebbe rientrare in un livello B. Cordiali Saluti.',
      'sentence_en': 'Dear Doctor, By viewing the photographs the case should fall in a level B.'
    },
    {
      'id': 1,
      'sentence_it': 'Buongiorno Dottore visionando le fotografie il caso dovrebbe rientrare in un livello C. Cordiali Saluti.',
      'sentence_en': 'Dear Doctor, By viewing the photographs the case should fall in a level C.'
    },
    {
      'id': 1,
      'sentence_it': 'Buongiorno Dottore visionando le fotografie il caso dovrebbe rientrare in un livello Plus. Cordiali Saluti.',
      'sentence_en': 'Dear Doctor, By viewing the photographs the case should fall in a level PLUS.'
    },
    {
      'id': 2,
      'sentence_it': 'Buongiorno Dottore il NuvolaView3D è online. Attendiamo un suo riscontro per accettazione o revisione. Cordiali saluti.',
      'sentence_en': 'Dear Doctor, NuvolaView3D is online. Awaiting for your response to accept or review the treatment plan. Kind Regards'
    },
    {
      'id': 2,
      'sentence_it': 'Buongiorno Dottore il NuvolaView3D è stato revisionato. Attendiamo un suo riscontro per accettazione o revisione.Cordiali saluti.',
      'sentence_en': 'Dear Doctor, NuvolaView3D has just been revised. Awaiting for your response to accept or review the treatment plan. Kind Regards.'
    },
    {
      'id': 2,
      'sentence_it': 'Buongiorno Dottore il Nuvola3D è online, il caso è complesso ed impredicibile e potrebbe essere necessario l\'utilizzo di ausiliari nelle fasi successive. Valuteremo fase per fase in base alla risposta del paziente. Attendiamo un suo riscontro per accettazione o revisione. Cordiali saluti.',
      'sentence_en': 'Dear Doctor, NuvolaView3D is online. This is a very hard and unpredictable case. Elastic tractions could be needed in next phases. Awaiting for your response to accept or review the treatment plan. Kind Regards.'
    },
    {
      'id': 3,
      'sentence_it': 'Buongiorno Dottore, al termine dello step di allineatori che le stiamo inviando, se non dovesse necessitare di ulteriori allineatori, potrà richiedere la stampa dei Retainers finali senza dover inviare nuove impronte. Cordiali Saluti.',
      'sentence_en': 'Dear Doctor, at the end of the last phase of the treatment, you can request the Retainers without sending a new impression. Kind Regards'
    },
    {
      'id': 4,
      'sentence_it': 'Posizionamento T-Guide su [n.dente] come serbatoio per estrusione naturale dell\'elemento.',
      'sentence_en': 'T-guide on [tooth number] as a tank for natural extrusion of the element'
    },
    {
      'id': 4,
      'sentence_it': 'Buongiorno Dottore, abbiamo posizionato un pontic sull\'elemento [x], se vuole puo\' indicarci il colore per renderlo estetico altrimenti non verrà colorato. Cordiali saluti',
      'sentence_en': 'Pontic on [tooth number]. if you want it to be coloured, please specify us the right colour, otherwise it will be blank'
    },
    {
      'id': 4,
      'sentence_it': 'La risoluzione dei Cross Bite posteriori risulta poco predicibile, pertanto potrebbe essere necessario il completamento con delle trazioni elastiche di Criss Cross.',
      'sentence_en': 'Cross Bite resoluton on posterior teeth is unpredictable. Criss Cross elastics could be needed in next phases'
    },
    {
      'id': 5,
      'sentence_it': 'Gentile Dottore Il Nuvola View è online. Abbiamo elaborato il caso seguendo gli obiettivi iniziali del trattamento. Per eventuali modifiche rimaniamo in attesa di un suo riscontro o di un eventuale telefonata per un confronto tecnico. Cordiali saluti.',
      'sentence_en': 'Dear Doctor, NuvolaView3D is online. We\'ve elaborated this case following the original Treatment Goals. If you want to change these goals, you can write in chat or contact us'
    },
    {
      'id': 5,
      'sentence_it': 'Gentile Dottore anche se il caso risulta scaduto, abbiamo deciso, comunque, di procedere con un\'ultima rifinitura compresa nel trattamento. Si raccomanda la massima collaborazione del paziente per raggiungere la chiusura del caso. Cordiali saluti.',
      'sentence_en': 'Dear Doctor, this case is expired, we decided to go ahead anyway with another last refinement. We recommend maximum cooperation from the patient to achieve Treatment goals'
    },
    {
      'id': 5,
      'sentence_it': 'Buongiorno Dottore, contatti il supporto live per conoscere dettagli sulla spedizione, le colleghe di reparto le sapranno darle tutte le informazioni necessarie. Questa è una chat di natura tecnica dove possiamo scambiare informazioni/indicazioni riguardanti la progettazione. Un cordiale saluto.',
      'sentence_en': 'Dear Doctor, you can contact Live Support to get shipping details, our collegues will give you all informations that you need. This is only a technical chat where you can discuss the Treatment Plan directly with your technician. Kind Regards'
    },
    {
      'id': 6,
      'sentence_it': 'Buongiorno Dottore, il caso è in fase di valutazione da parte del nostro Team di Ortodontisti per uno studio più approfondito del caso. Il NuvolaView3D verrà pubblicato entro pochi giorni',
      'sentence_en': 'Dear Doctor, this case is being evaluated by our Team of Orthodontists. NuvolaView3D will be online in a few days'
    },
    {
      'id': 6,
      'sentence_it': 'Buongiorno Dottore, il caso rientrerebbe nel protocollo Nuvola Junior, una sistematica idonea a trattare, con allineatori, i pazienti in dentizione mista precoce o anche completamente decidua. La sistematica necessita di un\'abilitazione, per qualunque info sulla certificazione può contattare il suo T.M. di riferimento. Cordiali saluti',
      'sentence_en': 'Dear Doctor, this case falls in Nuvola Junior Protocol. This is a system to treat patient in mixed or totally deciduous dentition, with aligners. You need to have enablement to use this system and for that you can ask to you sales agent.'
    },
    {
      'id': 6,
      'sentence_it': 'Buongiorno Dottore, in seguito ad un confronto con il nostro team di ortodontisti, le comunichiamo che il caso non rientra nel protocollo Nuvola Junior per via di una permuta troppo avanzata. Si consiglia di attendere il completamento dell\'eruzione di tutti gli elementi definitivi per poi trattare con allineatori. Cordiali saluti',
      'sentence_en': 'Dear Doctor, following a discussion with our team of orthodontists, this case doesn\'t fall in NuvolaJunior Protocol because the permutation is too advanced. We suggest to wait for the eruption of all definitive teeth to go ahead with a classic aligner treatment'
    },
    {
      'id': 7,
      'sentence_it': 'Buongiorno Dottore, elaborando la rifinitura abbiamo verificato che il caso risulta indietro rispetto alle previsioni di cura. Si raccomanda la massima collaborazione da parte del paziente per cercare di chiudere il trattamento nei tempi prestabiliti. Cordiali Saluti ',
      'sentence_en': 'Dear Doctor, elaborating this refinement, we have verified that the case appears to be behind the treatment forecast. We recommend maximum cooperation from the patient to achieve Treatment goals before the expiration date.'
    },
    {
      'id': 8,
      'sentence_it': 'Gentile Dottore, abbiamo provato a contattarla senza risposta. Può richiamarci al numero 06/72671754 per dei chiarimenti sul caso? Cordiali saluti.',
      'sentence_en': 'Dear Doctor, we tried to contact you to discuss about this case, but we did\'t get any answer. Can you call us back to the 06/72671754? Kind Regards'
    },
  ];
  chatSentences1:any[] = [];
  chatSentences2:any[] = [];
  chatSentences3:any[] = [];
  chatSentences4:any[] = [];
  chatSentences5:any[] = [];
  chatSentences6:any[] = [];
  chatSentences7:any[] = [];
  chatSentences8:any[] = [];

  @ViewChild('scroll', { read: ElementRef, static: true }) scrollElement: ElementRef;
  @ViewChild('scroll', { static: true }) scroll: ScrollbarComponent;
  public currentUser: User;
  storageBaseUrl: string = environment.storageBaseUrl;

  constructor(
    private cd: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
    private userService: UserService,
    private chatService: ChatService,
    private snackBar: MatSnackBar,
    private _translocoService: TranslocoService,
    private _contentService: ContentsService,
    private _messagingService: MessagingServiceService,
    private modalServide: MatDialog,
    private treatmentsService: TreatmentsService,
  ) {
    this._subscription = this._messagingService.getMessage().subscribe(res => {
      this.recieveNewMessage();
      this.cd.markForCheck();
      this.scrollToBottom();
    });
  }

  ngOnInit() {
    this.currentUser = this.userService.readUserFromLocalStorage();

    this.chatSentences1 = this.chatSentences.filter(x=>x.id == 1);
    this.chatSentences2 = this.chatSentences.filter(x=>x.id == 2);
    this.chatSentences3 = this.chatSentences.filter(x=>x.id == 3);
    this.chatSentences4 = this.chatSentences.filter(x=>x.id == 4);
    this.chatSentences5 = this.chatSentences.filter(x=>x.id == 5);
    this.chatSentences6 = this.chatSentences.filter(x=>x.id == 6);
    this.chatSentences7 = this.chatSentences.filter(x=>x.id == 7);
    this.chatSentences8 = this.chatSentences.filter(x=>x.id == 8);

    // if (this.chatType == 'I') {
    //   //refresh chat if START set as INCOMPLETE
    //   this.refreshIncompleteChat.subscribe(v => {
    //     if (v == true) {
    //       this.recieveNewMessage();
    //       this.cd.markForCheck();
    //       this.scrollToBottom();
    //     }
    //   });
    // }

    this.mediaObserver.asObservable().pipe(
    ).subscribe((change: MediaChange[]) => {
      const isMobile = this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm');

      if (isMobile) {
        this.drawerOpen = false;
        this.drawerMode = 'over';
        this.drawerDisableClose = false;
      } else {
        this.drawerOpen = true;
        this.drawerMode = 'side';
        this.drawerDisableClose = true;
      }
    });

    if (this.chatType == 'Q') {
      if (!this.quote.idQuote) {
        return;
      }
    } else if (this.chatType == 'T') {
      if (!this.treatment.idTreatment) {
        return;
      }
    } else if (this.chatType == 'I') {
      if (!this.treatment.idTreatment) {
        return;
      }
    }

    this.recieveNewMessage();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  showProfileAvatr(whoPhoto) {
    if (whoPhoto == null || whoPhoto == '')
      return 'assets/img/demo/avatars/noavatar.png';
    else
      return this.storageBaseUrl + '/users-photos/' + whoPhoto;
  }

  recieveNewMessage() {
    var counter = this.messages.length > 0
      ? this.messages[this.messages.length - 1].counter
      : 0;

    var id = 0;
    if (this.chatType == 'Q') {
      id = this.quote.idQuote;
    } else if (this.chatType == 'T') {
      id = this.treatment.idTreatment;
    } else if (this.chatType == 'I') {
      id = this.treatment.idTreatment;
    }

    this.chatService.getMessages(id, counter, this.chatType).subscribe((resp: any[]) => {

      if (resp == null) {
        return;
      }

      if (counter == 0) {
        this.messages = sortBy(resp, 'counter');
      } else {
        this.messages.push(...sortBy(resp, 'counter'))
      }

      //detect if the last message is of current user
      if (this.messages.length > 0) {
        var lastChatMessage = this.messages[this.messages.length - 1];
        this.isUserChatOwner.emit(lastChatMessage.whoId != this.currentUser.idAdmin);
      }

      this.scrollToBottom();
      this.cd.detectChanges();
    });
  }

  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  async send() {
    if (!this.isEmptyOrSpaces(this.newMessage)) {

      var id = 0;
      if (this.chatType == 'Q') {
        id = this.quote.idQuote;
      } else if (this.chatType == 'T') {
        id = this.treatment.idTreatment;
      } else if (this.chatType == 'I') {
        id = this.treatment.idTreatment;
      }

      this.chatService.sendMessage(id, this.newMessage, this.chatType).subscribe(resp => {
        this.newMessage = '';
        this.recieveNewMessage();
        this.cd.markForCheck();
        this.scrollToBottom();
      }, error => {
        this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
          duration: 3000,
          panelClass: "warning",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      });
    }
    this.newMessage = '';
  }


  scrollToBottom() {
    setTimeout(() => {
      this.scroll.scrollbar.scrollTo(0, this.scroll.scrollbar.getSize().content.height, 200);
    }, 100);
  }

  isAbleToSend() {

    if (this.currentUser.role == 'SA' || this.currentUser.role == 'DI' || this.currentUser.role == 'AG') {
      return false;
    }

    if (this.chatType == 'Q') {
      //QUOTES
      // if (this.messages.length > 0) {
      //   var lastChatMessage = this.messages[this.messages.length - 1];
      //   return (lastChatMessage.whoId != this.currentUser.idAdmin);
      // }

      if (this.quote.status == "MIGRATED")
        return false;

    } else if (this.chatType == 'T') {
      //TREATMENTS
      if (!this.treatment) {
        return false;
      }

      if (this.treatment.status == "APPROVED" || this.treatment.status == "PRODUCTION" || 
          this.treatment.status == "SHIPPED" || this.treatment.status == "REJECTED" ||
          this.treatment.status == 'ARCHIVED')
        return false;
    }
    else if (this.chatType == 'I') {
      //INCOMPLETE TREATMENTS
      if (!this.treatment) {
        return false;
      }
      if (this.treatment.status != "INCOMPLETE" || (this.currentUser.userGroup != 'ST' && this.currentUser.role != 'DO'))
        return false;
    }

    return true;
  }

  fileBrowseHandler(fileList) {
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      const current = new Date();
      const ext = file.name.split('.').pop();
      formData.append('FileObject', file, 'incomplete_img_' + current.getTime() + '.' + ext);
      this._contentService.uploadContentBlob(formData, "t-" + this.treatment.idTreatment, true).subscribe((resp: any) => {
        if (resp.type == 4) {
          let imageUrl = resp.body.fileUrl;
          let newMessage = `<a href="${imageUrl}" target="_blank"><img src="${imageUrl}" class="chat-thumbnail"/></a>`
          //send a chat message with this image
          this.chatService.sendMessage(this.treatment.idTreatment, newMessage, this.chatType).subscribe(resp => {
            this.recieveNewMessage();
            this.cd.markForCheck();
            this.scrollToBottom();
          }, error => {
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
              duration: 3000,
              panelClass: "warning",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
          });
        }
      });
    }
  }

  showChatSentences(ref){
    this.currentLanguage = this._translocoService.getActiveLang();
    this.chatSentencesModal = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: "lg-modal" })
  }

  closeChatSentencesModal(){
    this.chatSentencesModal.close();
  }

  showNotificationModal(ref){
    this.currentLanguage = this._translocoService.getActiveLang();
    this.sendNotificationModal = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: "lg-modal" })
  }

  closeSendNotificationModal(){
    this.sendNotificationModal.close();
  }

  sendNotification(){
    this.closeSendNotificationModal();
    this.treatmentsService.sendChatNotification(+this.treatment.idTreatment).subscribe(resp => {
      this.snackBar.open(this._translocoService.translate('patient.sendChatNotificationConfirm'), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }, error => {
      this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }

  loadChatTemplate(selectedItem){
    console.log('selectedItem',selectedItem);
    //this.chatSentenceCategorySelected=selectedItem;
  }

  doChooseSentence(item){
    let res='';
    console.log('doChooseSentence',item);
    if (this.currentLanguage== 'it'){
      res = item.sentence_it;
    }else if (this.currentLanguage== 'en'){
      res = item.sentence_en;
    }
    this.newMessage = res;
    this.chatSentencesModal.close();
  }

}
