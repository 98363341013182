import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from '../../modules/security/models/user.model';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';

const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;

@Injectable({
  providedIn: 'root'
})
export class TreatmentsService {

  private previousResponse: any[];
  private previousRequestParams: any;
  private filterParam: string;

  constructor(
    private _http: HttpClient
  ) { }

  public getDoctors() {
    const endPoint = '/api/admin/byrole/DO';
    return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((resp: User[]) => {
      return resp;
    }));
  }

  public getTreatments(request) {
    this.previousResponse = [];
    const endPoint = '/api/treatment/search';
    request = this.escapeRequestProp(request);
    return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers }).pipe(map((response: any[]) => {
      this.previousResponse = response;
      this.previousRequestParams = request;
      return response;
    }));
  }

  public getTreatmentsReport(request) {
    const endPoint = '/api/treatment/report';
    return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers }).pipe(map((response: any[]) => {
      return response;
    }));
  }

  public existByIdAddress(idAddress) {
    const endPoint = `/api/treatment/existsByIdAddrress/${idAddress}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  escapeRequestProp(request: any): any {
    for (const prop in request) {
      if (typeof (request[prop]) !== 'number') {
        if (!request[prop]) {
          delete (request[prop]);
        }
      }
    }
    return request;
  }

  public getPreviousReponse() {
    if (!this.previousResponse) {
      this.previousResponse = [];
    }
    return this.previousResponse;
  }

  public getPreviousRequest() {
    return this.previousRequestParams;
  }

  public reset() {
    this.previousRequestParams = null;
    this.previousResponse = [];
    this.filterParam = '';
  }

  public setFilterParams(val) {
    this.filterParam = val;
  }

  public getFilterParams() {
    return this.filterParam ? this.filterParam : '';
  }

  public changeStatus(reason: string, treatmentId: any, endpoint: string, request?: any) {
    const endPoint = `/api/treatment/changestatus/${endpoint}`;

    request = request ? request : {
      idTreatment: +treatmentId,
      reason: reason
    };

    return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public closeChat(treatmentId: any) {
    const endPoint = `/api/treatment/chat/closechat/${treatmentId}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public takeInCharge(idTreatment: any, idAdmin: any) {
    const endPoint = `/api/treatment/takeincharge/${idTreatment}/${idAdmin}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public closeChatIncomplete(treatmentId: any) {
    const endPoint = `/api/treatment/incomplete/chat/closechat/${treatmentId}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public setTreatmentPlan(plan: any) {
    const endPoint = `/api/treatmentplan`;
    return this._http.post(`${apiURL}${endPoint}`, plan, { headers: headers });
  }

  public createTreatmentPlanPDF(body: any, idTreatment: string, language: string) {
    const endPoint = `/api/treatmentplan/createpdf/${idTreatment}/${language}`;
    return this._http.post(`${apiURL}${endPoint}`, body, { headers: headers });
  }

  public getTreatmentPlanById(treatmentId: any) {
    const endPoint = `/api/treatmentplan/${treatmentId}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public goToNext(idTreatment: any, value: boolean) {
    const endPoint = `/api/treatmentplan/gotonext/${idTreatment}/${value}`;

    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public saveAdminData(treatmentId: any, request: any) {
    const endPoint = `/api/Treatmentplan/administrationdata/${treatmentId}`;
    return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });

  }

  public getScheduleTimes(arg0: string) {
    const endPoint = `/api/Treatment/PickupDates/${arg0}`;

    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  putScheduleTime(request: any) {
    const endPoint = `/api/Treatment/PickupDate`;

    return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public addAdditionalRequest_newPrint(req: any) {
    const endPoint = `/api/TreatmentPlan/AdditionalRequest/NewPrint`;
    return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
  }

  public addAdditionalRequest_newRetainers(req: any) {
    const endPoint = `/api/TreatmentPlan/AdditionalRequest/NewRetainers`;
    return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
  }

  public addAdditionalRequest_reMake(req: any) {
    const endPoint = `/api/TreatmentPlan/AdditionalRequest/Remake`;
    return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
  }

  public addAdditionalRequest_newFreedom(req: any) {
    const endPoint = `/api/TreatmentPlan/AdditionalRequest/NewFreedom`;
    return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
  }

  public addAdditionalRequest_updateStatus(req: any) {
    const endPoint = `/api/TreatmentPlan/AdditionalRequest`;
    return this._http.put(`${apiURL}${endPoint}`, req, { headers: headers });
  }

  public addAdditionalRequest_delete(idrequest: any) {
    const endPoint = `/api/TreatmentPlan/AdditionalRequest`;
    return this._http.delete(`${apiURL}${endPoint}/` + idrequest, { headers: headers });
  }

  public updateWebViewerFile(data: any) {
    const endPoint = `/api/treatment/updatewebviewerfile`;
    return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
  }

  public deleteWebViewer(treatmentId: any) {
    const endPoint = `/api/treatment/deletewebviewer/${treatmentId}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public updateAddresses(data: any) {
    const endPoint = `/api/treatment/updateaddresses`;
    return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
  }

  public generateTreatmentCode(treatmentId: any, archesType: string) {
    const endPoint = `/api/treatment/generatetreatmentcode/${treatmentId}/${archesType}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public updatePatientAdditionalNotes(idTreatment: any, treatmentCode: string, notes: string) {
    const endPoint = `/api/treatment/updatepatientnotes`;
    const data = {
      'idtreatment': +idTreatment,
      'treatmentCode': treatmentCode,
      'patientAdditionalNotes': notes
    };
    return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
  }

  public updateTreatmentPlanNotes(body: any) {
    const endPoint = `/api/treatmentplan/UpdateTreatmentPlanNotes`;
    return this._http.post(`${apiURL}${endPoint}`, body, { headers: headers });
  }

  public updateClinicPreferenceFlag(idDoctor: any) {
    const endPoint = `/api/admin/doctor/clinicprefflag/${idDoctor}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public updatePlannedShippingDate(body: any) {
    const endPoint = `/api/treatment/updateplannedshippingdate`;
    return this._http.post(`${apiURL}${endPoint}`, body, { headers: headers });
  }

  public removePlannedShippingDate(body: any) {
    const endPoint = `/api/treatment/DeletePlannedShippingDate/`;
    return this._http.get(`${apiURL}${endPoint}${body.idTreatment}`, { headers: headers });
  }

  public sendChatNotification(idTreatment: any) {
    const endPoint = `/api/treatment/sendchatnotification/${idTreatment}`;
    return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

}


