import * as tslib_1 from "tslib";
import { Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { TreatmentsNextPhaseModalComponent } from './treatments-next-phase-modal/treatments-next-phase-modal.component';
import { environment } from 'src/environments/environment';
export class TreatmentsNextPhaseComponent {
    constructor(route, _usersService, treatmentAdditionalFunctionalService, _patientService, cd, _router, fb, treatmentsService, _convertService, modalServide, _translocoService, snackBar, _breakpointObserver, _contentService, userService) {
        this.route = route;
        this._usersService = _usersService;
        this.treatmentAdditionalFunctionalService = treatmentAdditionalFunctionalService;
        this._patientService = _patientService;
        this.cd = cd;
        this._router = _router;
        this.fb = fb;
        this.treatmentsService = treatmentsService;
        this._convertService = _convertService;
        this.modalServide = modalServide;
        this._translocoService = _translocoService;
        this.snackBar = snackBar;
        this._breakpointObserver = _breakpointObserver;
        this._contentService = _contentService;
        this.userService = userService;
        this.progressUpload = 0;
        this.progressUploadSaving = false;
        this.singleFileProgressValue = 0;
        this.fileUploadProgressCounter = 0;
        this.currentFileIterator = 0;
        this.totalFileCount = 0;
        this.reload = false;
        this.isError = false;
        this.waitingForSTL = true;
        this.isUploading = false;
        this.speedUpload = 0;
        this.unitUpload = "Mbps";
        this.SaveAndSend = false;
        this.updateStatusNewValue = "";
        this.isUpdatieStillLoading = false;
        this.webViewerItems = [];
        this.isMovingEvent = false;
        this.ignoreModal = false;
        this.isLoading = true;
        this.imageTabOpen = true;
        this.VideoTabOpen = false;
        this.imageTabDisabled = false;
        this.showAddress = false;
        this.isMobile = false;
        this.fileToBeUploaded = [];
        //availableScheduleTime: string[] = [];
        this.availableScheduleTime = [];
        this.isStartUser = false;
        this.isUsaVersion = environment.isUsaVersion;
        this._breakpointObserver.observe([
            Breakpoints.Handset
        ]).subscribe(result => {
            this.isMobile = result.matches;
        });
        this._subsctiptions = this._router.events.subscribe((event) => {
        });
    }
    ngOnDestroy() {
        this._subsctiptions.unsubscribe();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.idTreatment = this.route.snapshot.paramMap.get('id');
            this.currentUser = yield this.userService.readUserFromLocalStorage();
            // if (this.currentUser.role == 'DO') {
            //   //DOCTOR
            //   this.nextPhaseForm = this.fb.group({
            //     //askForNuvolaView: [true],
            //     archesType: ['T'],
            //     archesSentViaCommunicator: [false],
            //     scanLower: [null],
            //     scanUpper: [null],
            //     return: ['null', [Validators.required, customNullValidator()]],
            //     ship: ['null', [Validators.required, customNullValidator()]],
            //     imageType: ['S', Validators.required],
            //     bottomArch: [null],
            //     upperArch: [null],
            //     leftOcclusal: [null],
            //     frontOcclusal: [null],
            //     rightOcclusal: [null],
            //     imageCollage: [null],
            //     noSpecialInstruction: [false],
            //     specialInstruction: [null, [Validators.required]]
            //   });
            // } else if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
            //   //START USER
            //   this.nextPhaseForm = this.fb.group({
            //     archesType: ['D'],
            //     archesSentViaCommunicator: [false],
            //     scanLower: [null, Validators.required],
            //     scanUpper: [null, Validators.required],
            //     return: ['null'],
            //     ship: ['null'],
            //     imageType: ['S'],
            //     bottomArch: [null],
            //     upperArch: [null],
            //     leftOcclusal: [null],
            //     frontOcclusal: [null],
            //     rightOcclusal: [null],
            //     imageCollage: [null],
            //     noSpecialInstruction: [false],
            //     specialInstruction: [null]
            //   });
            // }
            this.nextPhaseForm = this.fb.group({
                //askForNuvolaView: [true],
                archesType: ['D'],
                archesSentViaCommunicator: [false],
                scanLower: [null],
                scanUpper: [null],
                return: ['null', [Validators.required, customNullValidator()]],
                ship: ['null', [Validators.required, customNullValidator()]],
                imageType: ['S', Validators.required],
                bottomArch: [null],
                upperArch: [null],
                leftOcclusal: [null],
                frontOcclusal: [null],
                rightOcclusal: [null],
                imageCollage: [null],
                noSpecialInstruction: [false],
                specialInstruction: [null, [Validators.required]]
            });
            this._subsctiptions.add(this.nextPhaseForm.controls.archesSentViaCommunicator.valueChanges.pipe(debounceTime(25)).subscribe(value => {
                this.setSTLValidation();
            }));
            this.scheduleForm = this.fb.group({
                pickupDate: [null, Validators.required],
                pickupNote: ['']
            });
            //get treatment plan info to detect if it's a new phase or revision
            yield this.treatmentAdditionalFunctionalService.initService(this.idTreatment);
            this.selectedTreatment = yield this._patientService.getTreatment(this.idTreatment).toPromise();
            this.shippings = yield this._usersService.getAddressById(this.selectedTreatment.idDoctor, "S").toPromise();
            //If USA -> no returns
            if (!this.isUsaVersion) {
                this.returns = yield this._usersService.getAddressById(this.selectedTreatment.idDoctor, "R").toPromise();
                //check if idAddressReturn exists in list received
                let returnExists = this.returns.filter(x => x.idAddress == this.selectedTreatment.idAddressReturn);
                if (returnExists.length == 0)
                    this.nextPhaseForm.controls.return.setValue(null);
                else
                    this.nextPhaseForm.controls.return.setValue(this.selectedTreatment.idAddressReturn);
            }
            else {
                //remove mandatory for return
                this.nextPhaseForm.controls.return.setValue(null);
                this.nextPhaseForm.controls.return.clearValidators();
            }
            //check if idAddressShipping exists in list received
            let shippingExists = this.shippings.filter(x => x.idAddress == this.selectedTreatment.idAddressShipping);
            if (shippingExists.length == 0)
                this.nextPhaseForm.controls.ship.setValue(null);
            else
                this.nextPhaseForm.controls.ship.setValue(this.selectedTreatment.idAddressShipping);
            //No special instruction as default
            this.nextPhaseForm.controls.noSpecialInstruction.setValue(true);
            this.onSpecialInstructioCheckBox(true);
            this.phaseType = this.treatmentAdditionalFunctionalService.getPlanField('goToNextPhase'); //P = Phase; R=Revision
            //if (this.currentUser.role == 'DO') {
            //DOCTOR
            //this.imageTabOpen = this.phaseType == 'R';
            this.imageTabDisabled = this.phaseType == 'R';
            this.nextPhaseForm.controls.archesType.setValue('D');
            this.setSTLValidation();
            this.nextPhaseForm.controls.return.clearValidators();
            this.nextPhaseForm.controls.ship.clearValidators();
            // }
            // else if (this.currentUser.role == 'US' && this.currentUser.userGroup == 'ST') {
            //   //STRAT USER - allow only STL files
            //   this.isStartUser = true;
            //   //this.imageTabOpen = true;
            //   this.imageTabDisabled = true;
            //   this.nextPhaseForm.controls.archesType.setValue('D');
            //   this.nextPhaseForm.controls.archesType.disable();
            //   this.nextPhaseForm.controls.archesType.disable();
            //   this.showAddress = false;
            // }
            this.isLoading = false;
            this.cd.detectChanges();
        });
    }
    setSTLValidation() {
        let archesType = this.nextPhaseForm.controls.archesType.value;
        let archesSentViaCommunicator = this.nextPhaseForm.controls.archesSentViaCommunicator.value;
        if (archesType == "T") {
            //traditional
            this.nextPhaseForm.controls.scanLower.clearValidators();
            this.nextPhaseForm.controls.scanUpper.clearValidators();
        }
        else if (archesType == "D") {
            //digital
            if (archesSentViaCommunicator) {
                this.nextPhaseForm.controls.scanLower.clearValidators();
                this.nextPhaseForm.controls.scanUpper.clearValidators();
            }
            else {
                this.nextPhaseForm.controls.scanLower.setValidators(Validators.required);
                this.nextPhaseForm.controls.scanUpper.setValidators(Validators.required);
            }
        }
        this.nextPhaseForm.controls.scanUpper.setValue(null);
        this.nextPhaseForm.controls.scanLower.setValue(null);
        this.cd.detectChanges();
    }
    goBack() {
        this._router.navigate([`/treatments/${this.idTreatment}`]);
    }
    openConfirmModal(ref) {
        if (this.nextPhaseForm.valid) {
            this.modalMessage = "patient.NEXTPHASE.confirmMessage";
            this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
        }
        else {
            this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.errorMessage"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            this.nextPhaseForm.markAllAsTouched();
        }
    }
    closeConfirmModal() {
        this.confirmModal.close();
    }
    doCreateNextPhase(ref) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.closeConfirmModal();
            this.isLoading = true;
            let selectedDate = this.scheduleForm.controls.pickupDate.value;
            if (this.nextPhaseForm.controls.archesType.value == 'D') {
                selectedDate = null;
            }
            let request = {
                "idTreatment": +this.idTreatment,
                "sendImages": this.imageTabOpen,
                "sendVideo": this.VideoTabOpen,
                //"askNuvolaView": this.nextPhaseForm.controls.askForNuvolaView.value,
                "archesType": this.imageTabOpen ? this.nextPhaseForm.controls.archesType.value : this.selectedTreatment.archesType,
                "idAddressReturn": this.nextPhaseForm.controls.return.value == null ? -1 : this.nextPhaseForm.controls.return.value,
                "idAddressShipping": this.nextPhaseForm.controls.ship.value == null ? -1 : this.nextPhaseForm.controls.ship.value,
                "archesSentViaCommunicator": this.nextPhaseForm.controls.archesSentViaCommunicator.value,
                "imageType": this.nextPhaseForm.controls.imageType.value,
                "imageUA": this.nextPhaseForm.controls.upperArch.value,
                "imageBA": this.nextPhaseForm.controls.bottomArch.value,
                "imageLO": this.nextPhaseForm.controls.leftOcclusal.value,
                "imageFO": this.nextPhaseForm.controls.frontOcclusal.value,
                "imageRO": this.nextPhaseForm.controls.rightOcclusal.value,
                "imageCollage": this.nextPhaseForm.controls.imageCollage.value,
                "scanLower": this.nextPhaseForm.controls.scanLower.value,
                "scanUpper": this.nextPhaseForm.controls.scanUpper.value,
                "noSpecialInstruction": this.nextPhaseForm.controls.noSpecialInstruction.value,
                "specialInstruction": this.nextPhaseForm.controls.specialInstruction.value,
                "pickupDate": selectedDate == null ? null : selectedDate[0],
                "pickupDate2": selectedDate != null && selectedDate.length == 2 ? selectedDate[1] : null,
                "pickupNote": this.scheduleForm.controls.pickupNote.value,
                "isMigrated": this.selectedTreatment.isOld
            };
            this._patientService.gotoNextPhaseRevision(request).subscribe((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (resp.result) {
                    this.idTreatment = resp.idTreatment;
                    if (this.fileToBeUploaded.length != 0 && ref != null) {
                        this.fileLoadProgressModal = this.modalServide.open(ref, { closeOnNavigation: true, disableClose: true, panelClass: "lg-modal" });
                        this.uploadFileScanc(this.fileToBeUploaded[0]);
                    }
                    else {
                        this.ignoreModal = true;
                        this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.savingSuccess"), "X", {
                            duration: 3000,
                            panelClass: "success",
                            horizontalPosition: "right",
                            verticalPosition: "top",
                        });
                        this._router.navigate(["/treatments", this.idTreatment]);
                    }
                }
                else {
                    this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
                        duration: 3000,
                        panelClass: "warning",
                        horizontalPosition: "right",
                        verticalPosition: "top",
                    });
                }
                this.isLoading = false;
                this.cd.detectChanges();
            }), error => {
                this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
                    duration: 3000,
                    panelClass: "warning",
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
                this.isLoading = false;
                this.cd.detectChanges();
            });
        });
    }
    uploadFileScanc(file) {
        this.progressUploadSaving = false;
        this.progressUpload = 0;
        ++this.currentFileIterator;
        this.reload = true;
        this.isUploading = true;
        let percentDone;
        let currTime;
        let prevTime;
        let bytesReceied = 0;
        let oldbytes = 0;
        //setting start time
        this.uploadSub = this._contentService.uploadContentBlob(file.formData, `t-${this.idTreatment}`, true).subscribe({
            next: (event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        //upload speed
                        percentDone = Math.round((100 * event.loaded) / event.total);
                        currTime = new Date().getTime();
                        //tracking how much data is received
                        bytesReceied = event.loaded / 1000000;
                        //calculating download speed per percent data received
                        this.speedUpload = (bytesReceied - oldbytes) / ((currTime - prevTime) / 1000);
                        if (this.speedUpload < 1) {
                            this.unitUpload = "Kbps";
                            this.speedUpload *= 1000;
                        }
                        else
                            this.unitUpload = "Mbps";
                        //updating previous values
                        prevTime = currTime;
                        oldbytes = bytesReceied;
                        this.speedUpload = Math.round(this.speedUpload);
                        console.log('speed: ' + this.speedUpload + ' ' + this.unitUpload);
                        this.progressUpload = this.round(event.loaded / event.total * 100, 1);
                        this.progressUploadSaving = this.progressUpload == 100;
                        break;
                    case HttpEventType.Response:
                        console.log('File successfully created!', event.body);
                        this.progressUploadSaving = false;
                        break;
                }
            }, error: () => {
                this.request[file.fieldName] = "";
                this.isError = true;
                this.isUploading = false;
                this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.fileErrorMessage"), "X", {
                    duration: 3000,
                    panelClass: "warning",
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
                this._router.navigate(["/treatments", this.idTreatment]);
            }, complete: () => {
                this.isUploading = false;
                if (this.fileToBeUploaded.indexOf(file) != this.fileToBeUploaded.length - 1) {
                    this.uploadFileScanc(this.fileToBeUploaded[this.fileToBeUploaded.indexOf(file) + 1]);
                    return;
                }
                else {
                    this.ignoreModal = true;
                    this.snackBar.open(this._translocoService.translate("patient.NEXTPHASE.savingSuccess"), "X", {
                        duration: 3000,
                        panelClass: "success",
                        horizontalPosition: "right",
                        verticalPosition: "top",
                    });
                    this.fileLoadProgressModal.close();
                    this._router.navigate(["/treatments", this.idTreatment]);
                }
            }
        });
    }
    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    onSpecialInstructioCheckBox(checked) {
        if (checked) {
            this.nextPhaseForm.controls.specialInstruction.disable({ onlySelf: true });
        }
        else {
            this.nextPhaseForm.controls.specialInstruction.enable({ onlySelf: true });
        }
        this.nextPhaseForm.controls.specialInstruction.reset();
    }
    showHideAddress(event) {
        this.setSTLValidation();
        switch (this.nextPhaseForm.controls.archesType.value) {
            case "D":
                this.showAddress = false;
                this.nextPhaseForm.controls.return.clearValidators();
                this.nextPhaseForm.controls.return.reset();
                //this.nextPhaseForm.controls.ship.clearValidators();
                this.nextPhaseForm.controls.ship.reset();
                break;
            case "T":
                this.showAddress = true;
                this.nextPhaseForm.controls.return.setValidators(Validators.required);
                //this.nextPhaseForm.controls.ship.setValidators(Validators.required);
                break;
        }
    }
    getAvailableScheduleDate(row) {
        let date = new Date(row);
        let prefix = this._translocoService.translate("patient.GENERAL_INFO.plannedPickupOf");
        return `${date.getHours()}:00-${date.getHours() + 4}:00 ${prefix} ${this._convertService.shortDateConvert(row)}`;
    }
    openScheduleModal(ref) {
        this.treatmentsService.getScheduleTimes(this._convertService.dateConvert(new Date().toUTCString(), "YYYY-MM-DDTHH:MM:SS")).subscribe((resp) => {
            //this.availableScheduleTime = resp;
            this.availableScheduleTime = [];
            for (let date of resp) {
                let iteratorDate = new Date(date);
                if (this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() == iteratorDate.getDate())) != null && this.availableScheduleTime.length != 0) {
                    this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() == iteratorDate.getDate())).push(date);
                    continue;
                }
                let arrayOfDate = [];
                arrayOfDate.push(date);
                this.availableScheduleTime.push(arrayOfDate);
            }
        });
        this.schedulePickUp = this.modalServide.open(ref, { panelClass: "lg-modal", closeOnNavigation: true });
    }
    closeScheduleModal() {
        this.schedulePickUp.close();
    }
    confirmSchedule() {
        let selectedDate = this.scheduleForm.controls.pickupDate.value;
        if (!this.scheduleForm.valid || selectedDate.length == 0) {
            this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.scheduleTimeSelectError"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            return;
        }
        else {
            this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.scheduleTimeSuccess"), "X", {
                duration: 3000,
                panelClass: "success",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            this.closeScheduleModal();
        }
    }
    clearFileHandle(side, fileName) {
        if (!this.fileToBeUploaded.find(x => x.fileName == fileName)) {
            this.nextPhaseForm.controls[side].setValue(null);
            this.nextPhaseForm.touched;
            //this.save(true);
            return;
        }
        this.fileToBeUploaded = this.fileToBeUploaded.filter(x => x.fileName !== fileName);
        this.nextPhaseForm.controls[side].setValue(null);
        this.nextPhaseForm.touched;
    }
    downloadFileHandle(fileName) {
        this._contentService.getContentFile(`t-${this.idTreatment}`, fileName).subscribe((resp) => {
            FileSaver(resp, fileName);
            this.cd.detectChanges();
        }, error => {
            this.snackBar.open(this._translocoService.translate("COMMON.errorDownloadFileMessage"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        });
    }
    openFileHandle(fileName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let resp = yield this._contentService.getContentLink(`t-${this.idTreatment}`, fileName).toPromise();
            let image_window = window.open(resp.fullUrl, resp.fullUrl);
            image_window.document.write(`
     <html>
       <head>
       </head>
       <body>
         <img src="${resp.fullUrl}" width="50%">
       </body>
     </html>`);
        });
    }
    dropFileHandle(side, entity) {
        this.nextPhaseForm.controls[side].setValue(entity.fileName);
        if (!this.fileToBeUploaded.includes(x => x.fileName == entity.fileName)) {
            let propName = entity.fileName.split('.')[0];
            propName = `${propName.charAt(0).toLocaleLowerCase()}${propName.slice(1)}`;
            this.fileToBeUploaded.push({ fileName: entity.fileName, formData: entity.formData, fieldName: propName });
        }
        else {
            for (let file of this.fileToBeUploaded) {
                if (file.fileName == entity.fileName) {
                    file.formData = entity.formData;
                }
            }
        }
        this.nextPhaseForm.touched;
    }
    copied(value) {
        if (value.isSuccess) {
            this.snackBar.open(this._translocoService.translate("patient.copiedSuccess"), "X", {
                duration: 3000,
                panelClass: "success",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        }
    }
    onScheduleTimeChecked(event, row) {
        let selectedDate = this.scheduleForm.controls.pickupDate.value;
        if (!event.checked) {
            selectedDate = selectedDate.filter(x => x != row);
            this.scheduleForm.controls.pickupDate.setValue(selectedDate);
            return;
        }
        if (selectedDate == null || selectedDate.length == 0) {
            selectedDate = [];
            selectedDate.push(row);
            this.scheduleForm.controls.pickupDate.setValue(selectedDate);
            return;
        }
        if (selectedDate.length == 2) {
            event.source._checked = false;
            this.snackBar.open(this._translocoService.translate("COMMON.allowedSelectedMaximumLimit"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            return;
        }
        if (new Date(row).getDate() != new Date(selectedDate[0]).getDate()) {
            event.source._checked = false;
            this.snackBar.open(this._translocoService.translate("COMMON.couldBeSelectedOnlySameDay"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            return;
        }
        selectedDate.push(row);
        this.scheduleForm.controls.pickupDate.setValue(selectedDate);
    }
    openExitWithoutSaveModal() {
        return Observable.create((observer) => {
            let dialogRef = this.modalServide.open(TreatmentsNextPhaseModalComponent, {
                width: '500px',
                disableClose: true,
                data: { idTreatment: this.selectedTreatment.idTreatment }
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log('res', result);
                observer.next(result);
                observer.complete();
            }, (error) => {
                observer.next(false);
                observer.complete();
            });
        });
    }
}
export function customNullValidator() {
    return (control) => {
        return control.value == "null" ? { value: control.value } : null;
    };
}
