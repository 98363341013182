import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;
export class TreatmentsService {
    constructor(_http) {
        this._http = _http;
    }
    getDoctors() {
        const endPoint = '/api/admin/byrole/DO';
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((resp) => {
            return resp;
        }));
    }
    getTreatments(request) {
        this.previousResponse = [];
        const endPoint = '/api/treatment/search';
        request = this.escapeRequestProp(request);
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers }).pipe(map((response) => {
            this.previousResponse = response;
            this.previousRequestParams = request;
            return response;
        }));
    }
    getTreatmentsReport(request) {
        const endPoint = '/api/treatment/report';
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers }).pipe(map((response) => {
            return response;
        }));
    }
    existByIdAddress(idAddress) {
        const endPoint = `/api/treatment/existsByIdAddrress/${idAddress}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    escapeRequestProp(request) {
        for (const prop in request) {
            if (typeof (request[prop]) !== 'number') {
                if (!request[prop]) {
                    delete (request[prop]);
                }
            }
        }
        return request;
    }
    getPreviousReponse() {
        if (!this.previousResponse) {
            this.previousResponse = [];
        }
        return this.previousResponse;
    }
    getPreviousRequest() {
        return this.previousRequestParams;
    }
    reset() {
        this.previousRequestParams = null;
        this.previousResponse = [];
        this.filterParam = '';
    }
    setFilterParams(val) {
        this.filterParam = val;
    }
    getFilterParams() {
        return this.filterParam ? this.filterParam : '';
    }
    changeStatus(reason, treatmentId, endpoint, request) {
        const endPoint = `/api/treatment/changestatus/${endpoint}`;
        request = request ? request : {
            idTreatment: +treatmentId,
            reason: reason
        };
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    closeChat(treatmentId) {
        const endPoint = `/api/treatment/chat/closechat/${treatmentId}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    takeInCharge(idTreatment, idAdmin) {
        const endPoint = `/api/treatment/takeincharge/${idTreatment}/${idAdmin}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    closeChatIncomplete(treatmentId) {
        const endPoint = `/api/treatment/incomplete/chat/closechat/${treatmentId}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    setTreatmentPlan(plan) {
        const endPoint = `/api/treatmentplan`;
        return this._http.post(`${apiURL}${endPoint}`, plan, { headers: headers });
    }
    createTreatmentPlanPDF(body, idTreatment, language) {
        const endPoint = `/api/treatmentplan/createpdf/${idTreatment}/${language}`;
        return this._http.post(`${apiURL}${endPoint}`, body, { headers: headers });
    }
    getTreatmentPlanById(treatmentId) {
        const endPoint = `/api/treatmentplan/${treatmentId}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    goToNext(idTreatment, value) {
        const endPoint = `/api/treatmentplan/gotonext/${idTreatment}/${value}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    saveAdminData(treatmentId, request) {
        const endPoint = `/api/Treatmentplan/administrationdata/${treatmentId}`;
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    getScheduleTimes(arg0) {
        const endPoint = `/api/Treatment/PickupDates/${arg0}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    putScheduleTime(request) {
        const endPoint = `/api/Treatment/PickupDate`;
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    addAdditionalRequest_newPrint(req) {
        const endPoint = `/api/TreatmentPlan/AdditionalRequest/NewPrint`;
        return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
    }
    addAdditionalRequest_newRetainers(req) {
        const endPoint = `/api/TreatmentPlan/AdditionalRequest/NewRetainers`;
        return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
    }
    addAdditionalRequest_reMake(req) {
        const endPoint = `/api/TreatmentPlan/AdditionalRequest/Remake`;
        return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
    }
    addAdditionalRequest_newFreedom(req) {
        const endPoint = `/api/TreatmentPlan/AdditionalRequest/NewFreedom`;
        return this._http.post(`${apiURL}${endPoint}`, req, { headers: headers });
    }
    addAdditionalRequest_updateStatus(req) {
        const endPoint = `/api/TreatmentPlan/AdditionalRequest`;
        return this._http.put(`${apiURL}${endPoint}`, req, { headers: headers });
    }
    addAdditionalRequest_delete(idrequest) {
        const endPoint = `/api/TreatmentPlan/AdditionalRequest`;
        return this._http.delete(`${apiURL}${endPoint}/` + idrequest, { headers: headers });
    }
    updateWebViewerFile(data) {
        const endPoint = `/api/treatment/updatewebviewerfile`;
        return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
    }
    deleteWebViewer(treatmentId) {
        const endPoint = `/api/treatment/deletewebviewer/${treatmentId}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updateAddresses(data) {
        const endPoint = `/api/treatment/updateaddresses`;
        return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
    }
    generateTreatmentCode(treatmentId, archesType) {
        const endPoint = `/api/treatment/generatetreatmentcode/${treatmentId}/${archesType}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updatePatientAdditionalNotes(idTreatment, treatmentCode, notes) {
        const endPoint = `/api/treatment/updatepatientnotes`;
        const data = {
            'idtreatment': +idTreatment,
            'treatmentCode': treatmentCode,
            'patientAdditionalNotes': notes
        };
        return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
    }
    updateTreatmentPlanNotes(body) {
        const endPoint = `/api/treatmentplan/UpdateTreatmentPlanNotes`;
        return this._http.post(`${apiURL}${endPoint}`, body, { headers: headers });
    }
    updateClinicPreferenceFlag(idDoctor) {
        const endPoint = `/api/admin/doctor/clinicprefflag/${idDoctor}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updatePlannedShippingDate(body) {
        const endPoint = `/api/treatment/updateplannedshippingdate`;
        return this._http.post(`${apiURL}${endPoint}`, body, { headers: headers });
    }
    removePlannedShippingDate(body) {
        const endPoint = `/api/treatment/DeletePlannedShippingDate/`;
        return this._http.get(`${apiURL}${endPoint}${body.idTreatment}`, { headers: headers });
    }
    sendChatNotification(idTreatment) {
        const endPoint = `/api/treatment/sendchatnotification/${idTreatment}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
}
TreatmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TreatmentsService_Factory() { return new TreatmentsService(i0.ɵɵinject(i1.HttpClient)); }, token: TreatmentsService, providedIn: "root" });
